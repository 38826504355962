import React from "react";
import { PageProps, navigate } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { ButtonOutlined, ButtonPrimary, LayoutBild, SEO } from "@components";
import Higher from "@components/OurSolutions/Higher/Higher";
import PersonalTrainer from "@components/OurSolutions/ManageFinances/ManageFinances";
import DiscoverRokin from "@components/PageLanding/DiscoverRokin";
import Contact from "@components/PageLanding/Contact";
import { RokinComplete } from "@images";
import { APP_FRONT_PAGE, APP_FRONT_PAGE_CUT } from "images/GCP";
import { useAuth } from "@hooks";
import { Route } from "@interfaces";

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 800px)");
  const { isLoggedIn } = useAuth();
  return (
    <>
      <div className={classes.container}>
        <img
          src={isMobile ? APP_FRONT_PAGE_CUT : APP_FRONT_PAGE}
          alt=""
          className={classes.backImage}
        />
        <div className={classes.logo}>
          <div className={classes.logoContainer}>
            <img src={RokinComplete} alt="logo" />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <ButtonPrimary
            onClick={() =>
              isLoggedIn ? navigate(Route.finance) : navigate(Route.login)
            }
            text="Entrar"
            noDegraded
            className={classes.button}
          />
          <ButtonOutlined
            onClick={() => {
              navigate(Route.register);
            }}
            text="Registrarme"
            className={classes.button}
          />
        </div>
      </div>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#00323C",
    minHeight: "100vh",
    paddingBottom: "5rem",
  },
  backImage: {
    [theme.breakpoints.down(1300)]: {
      height: "34rem",
      objectFit: "cover",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "2.5rem",
      alignItems: "center",
      marginTop: "-7.5rem",
    },
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-25rem",
  },
  logoContainer: {
    width: "16.116rem",
    height: "6.972rem",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(12px)",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: "24px",
    padding: "1.2rem 1.6rem 1.8rem 1.6rem",
    " & -webkit-backdrop-filter": "blur(12px)",
  },
  button: {
    width: "11.25rem",
    color: "#FFFFFF",
  },
}));
